<template>
  <div class="icone-component">
    <div>
      <div class="icone-title" v-bind:style="{ color: color, 'background-color': bgcolor }">
        <i class="fa fa-lg" :class="icone" aria-hidden="true"></i>
      </div>
    </div>
    <div class="title is-size-4 has-text-weight-medium">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "IconComponent",
  data: function() {
    return {

    }
  },
  mounted() {
  },
  props: {
    title: String,
    icone: String,
    color: String,
    bgcolor: String,
  },
  computed: {
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>

.icone-component {
  display: flex;
  align-items: center;
}

.icone-title {
  height: 60px;
  width: 60px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.icone-title i {
  font-weight: bold;
}

</style>